<template>
  <b-modal
    ref="recalcularDas"
    size="sm"
    centered
    hide-footer
    hide-header
    @hidden="hide"
  >
    <div>
      <div class="text-center">
        <font-awesome-icon
          icon="fa-circle-exclamation"
          class="text-warning mt-2"
          size="8x"
        />
        <div class="mt-2">
          <h3>Deseja recalcular o DAS?</h3>
          <span>Para recalcular basta selecionar a data a ser consolidada no campo abaixo.</span>
        </div>
        <b-row>
          <b-form-datepicker
            v-model="dataRecalculo"
            :date-disabled-fn="isToDesableDate"
            :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
            variant="primary"
            placeholder="Data para recalculo"
            class="mb-2 mt-2 ml-5 col-8"
          />
          <feather-icon
            v-b-tooltip.hover
            title="Se a data escolhida for um feriado o sistema reagendará para o próximo dia útil."
            icon="InfoIcon"
            size="24"
            style="margin-top: 1.5vw;"
            class="ml-50 text-danger"
          />
        </b-row>
        <button
          class="p-60 btn btn-outline-primary mb-50"
          @click="recalcular"
        >
          Ok
        </button>
      </div>
    </div>
  </b-modal>
</template>

  <style lang="scss">
  </style>

<script>

export default {
  data() {
    return {
      dataRecalculo: '',
      guiaId: 0,
    }
  },
  methods: {
    hide() {
      this.guiaId = 0
    },
    isToDesableDate(ymd, date) {
      const weekday = date.getDay()
      const today = new Date()
      const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())
      return weekday === 0 || weekday === 6 || todayDate >= date || todayDate.getMonth() < date.getMonth()
    },
    show(id) {
      this.guiaId = id
      this.$refs.recalcularDas.show()
    },
    recalcular() {
      if (this.dataRecalculo) {
        this.$emit('recalcular', { id: this.guiaId, data: this.dataRecalculo })
        this.$refs.recalcularDas.hide()
      } else {
        this.$erro('Necessário informar a data para recalculo da guia')
      }
    },
  },
}
</script>
